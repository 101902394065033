.react-select {
  width: 4em;
  background-color: rgb(242, 175, 28);
  border: solid rgb(200, 144, 21) 0;
  border-bottom: solid rgb(200, 144, 21) 0.2em;
  outline: solid black 0.1em;
  border-radius: 0.5em;
  padding: 0 0.5em;
  padding-bottom: 0.5em;
  user-select: none;

  transition:
    border-color 0.1s,
    background-color 0.1s,
    color 0.1s,
    outline 0.1s;
}

.react-select *[class$='placeholder'] {
  transition: transform 0.1s;
  transform: scale(1);
}

.react-select:hover *[class$='placeholder'] {
  transform: scale(0.9);
}

.react-select:hover {
  border-color: transparent;
  outline: solid white 0.1em;
}

/* I hate css in js :cry: */
.react-select *[class$='menu'] {
  background-color: rgb(242, 175, 28);
  border: solid rgb(200, 144, 21) 0;
  border-bottom: solid rgb(200, 144, 21) 0.2em;
  outline: solid black 0.1em;
  border-radius: 0.5em;
}

.react-select *[class$='option'] {
  transition:
    transform 0.1s,
    color 0.1s;
  transform: scale(1);
  color: white;
}

.react-select *[class$='option']:hover {
  transform: scale(0.9);
  color: rgb(255, 71, 178);
}

.react-select *[class$='placeholder'],
.react-select *[class$='singleValue'],
.react-select *[class$='option'] {
  padding: 0 0.5em;
}

.react-select *[class$='control'] {
  min-height: unset;
}
