.modal {
  position: fixed;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(84, 64, 187);
  border: solid rgb(200, 144, 21) 0;
  outline: solid black 0.1em;
  border-radius: 0.5rem;
  padding: 2em;
  z-index: 1000;
  visibility: hidden;
  width: 80%;
  max-width: 30rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8em;

  transition:
    /* EaseInBack https://www.cssportal.com/css-cubic-bezier-generator/ */
    top cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.33s 0s,
    visibility linear 0s 0.33s;
}

.modal.open {
  visibility: visible;
  top: 50%;
  transition:
    /* EaseOutIn https://www.cssportal.com/css-cubic-bezier-generator/ */
    top cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.33s 0s,
    visibility linear 0s 0s;
}

.modalBackdrop {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: black;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity ease 0.33s,
    visibility linear 0s 0.33s;
}

.modalBackdrop.open {
  opacity: 0.75;
  visibility: visible;
  pointer-events: auto;
  transition:
    opacity ease 0.33s,
    visibility linear 0s 0s;
}

.modal h2,
.modal p {
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.modal button {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  border-radius: 0.5rem;
}
