.Button {
  background: none;
  color: inherit;
  border: none;

  font-family: 'Friendly Fire';
  text-transform: uppercase;
  text-shadow:
    /* Basic Outline */
    -0.1em -0.1em 0 #000,
    0.1em -0.1em 0 #000,
    -0.1em 0.1em 0 #000,
    0.1em 0.1em 0 #000,
    0em -0.1em 0 #000,
    0.1em 0em 0 #000,
    0em 0.1em 0 #000,
    -0.1em 0em 0 #000;
  letter-spacing: 0.1em;

  background-color: rgb(242, 175, 28);
  border: solid rgb(200, 144, 21) 0;
  border-bottom: solid rgb(200, 144, 21) 0.2em;
  outline: solid black 0.1em;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  padding-bottom: 0.5rem;
  user-select: none;

  transition:
    border-color 0.1s,
    background-color 0.1s,
    color 0.1s,
    outline 0.1s;
}

.Button:hover {
  border-color: transparent;
  outline: solid white 0.1em;
}

.Button:active {
  border-color: transparent;
  background-color: white;
}

.Button.forceActive {
  border-color: transparent;
  background-color: white;
  color: rgb(255, 71, 178);
}

.Button > div {
  transition: transform 0.1s;
  transform: scale(1);
}

.Button:hover > div,
.Button:active > div,
.Button.forceActive > div {
  transform: scale(0.9);
}

.Button > * {
  transition: transform 0.1s;
  transform: scale(1);
}

.Button:hover > *,
.Button:active > *,
.Button.forceActive > * {
  transform: scale(0.9);
}
